.inputLabels {
  display: unset !important;
  font-size: 0.875rem !important;
  color: black !important;
}

.inputFields {
  width: 100%;
  background-color: white;
  margin-top: 0.3rem !important;
}

.pacCard {
  position: absolute !important;
  width: 100% !important;
  height: 400px !important;
  z-index: 1 !important;
}

.autocompleteContainer {
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.commonSuggestionsClass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.23) !important;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
}

.mapIcon {
  margin-right: 0.5rem;
}

.suggestionItem {
  background-color: #ffffff;
}

.suggestionItemActive {
  background-color: #fafafa;
}
