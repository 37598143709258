$colorPrimary: rgb(51, 51, 51);
$colorSecondary: rgb(91, 112, 131);
$colorBlue: #1976d2; //#6200EE
$colorLightGray: #F2F3F5;
$colorGray: gray;
$colorLighterGray: #cecece;
$colorGreen: #4DA258;
$colorDanger: rgb(220, 0, 78);
$colorWhite: #FFF;
$appHeaderHeight: 55px;
$mobileBottomNavHeight: 60px;
$inputDisabledBg: #f6f6f6;

$headerBg: #F6F6F6;
$leftMessageBg: #E9E9E9;
$chatInputBg: #F0F0F0;
$searchContainerHeight: 60px;
$chatFormHeight: 60px;
$borderColor: rgba(51, 51, 51, 0.07);
$listingsCardShadow: 0 1px 3px 1px rgb(66 66 66 / 20%), 0 2px 8px 4px rgb(66 66 66 / 10%);