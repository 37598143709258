@import '../../../Variables';

.inputContainer {
  .inputLabels {
    display: flex !important;
    font-size: 1.2rem !important;
    color: black;
    align-items: center;
  }

  .datePicker {
    &:hover {
      cursor: pointer;
    }
  }

  .inputContainer {
    input {
      padding: 12px;
    }
  }

  .inputFields {
    width: 100%;
    background-color: white;
    margin-top: 1rem;
  }
}