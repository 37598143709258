@import "../../../Variables";

.inputContainer {

  .label {
    display: unset !important;
    font-size: 0.875rem;
    color: black !important;
  }

  .toggleButtonGroup {
    margin-top: 0.3rem;
  }

  .error {
    border: 1px solid red !important;
    margin-top: 0.3rem;
    width: auto !important;
  }

  .toggleButton {
    color: $colorPrimary;
    padding: 9px 10px;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px;
    min-width: 50px;

    .itemLabel {
      color: #FFF !important
    }

    .delete {
      visibility: visible;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .checked {
    background-color: $colorBlue !important;
    color: #fff !important;
  }
}