@import "../../../Variables";

.dialog {
  .limit {
    color: $colorSecondary
  }
}

.inputLabels {
  display: unset !important;
  font-size: 0.875rem !important;
  color: black !important;
}

.inputPriceFields {
  width: 100%;
  background-color: white;
  margin-top: 0.3rem !important;

  input {
    padding: 12px 12px 12px 30px;
  }
}

.priceInputLayout {
  position: relative;
}

.icon {
  z-index: 1;
  top: 1.2rem;
  left: 0.5rem;
  position: absolute;
  font-size: 14px !important;
}

.socialButtons {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;

  button {
    text-transform: inherit;
    font-weight: bold;
    background: #F2F3F5;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 7%), 0px 2px 2px 0px rgb(0 0 0 / 7%), 0px 1px 5px 0px rgb(0 0 0 / 7%);
    color: $colorPrimary;

    &:hover {
      color: white;
    }

    img {
      position: absolute;
      left: 10px;
    }
  }
}

.checked {
  background-color: #6d6ad7 !important;
  color: #fff !important;
  border: 1px solid #fff !important;

  span {
    color: #fff !important;
  }
}