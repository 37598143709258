@import "Variables.scss";

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  color: $colorPrimary;

  ::-webkit-scrollbar {
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background: $colorPrimary;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #FFF;
  }
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  scroll-behavior: smooth;
  padding-right: 0 !important;
  background-color: #FFF !important;

  .itemLink:link {
    color: $colorPrimary;
    text-decoration: none;
  }
}

input, textarea {
  max-width: 100%;
}

.mainLoader {
  height: 100vh;
  padding: 1px;
  display: flex;
  align-items: center;
}

.secondaryTwo {
  background-color: #59c3ff !important;
  color: white !important;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

button {
  .circular-progress {
    margin: 0 0 0 0.7rem;
    color: #fff;
  }

  .circular-progress-primary {
    margin: 0 0 0 0.7rem;
    color: $colorBlue;
  }
}

.loader {
  border-radius: 50%;
  width: 10em;
  height: 10em;
  font-size: 4px;
  margin: 60px auto;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #f3ebeb;
  border-bottom: 1.1em solid #f3ebeb;
  border-left: 1.1em solid #333333;
  border-right: 1.1em solid #f3ebeb;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.5s infinite linear;
  -moz-animation: load8 0.5s infinite linear;
  animation: load8 0.5s infinite linear;

  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
}


$max: 100;
$maxMargin: 5;
$maxPadding: 5;

@for $i from 1 through $max {
  .fs-#{$i} {
    font-size: #{$i}px !important;
  }
}

.m-unset {
  margin: unset !important;
}

@for $i from 0 through $maxMargin {
  .m-#{$i} {
    margin: #{$i*0.25}rem !important;
  }
  .mx-#{$i} {
    margin-left: #{$i*0.25}rem !important;
    margin-right: #{$i*0.25}rem !important;
  }
  .my-#{$i} {
    margin-top: #{$i*0.25}rem !important;
    margin-bottom: #{$i*0.25}rem !important;
  }
  .mt-#{$i} {
    margin-top: #{$i*0.25}rem !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i*0.25}rem !important;
  }
  .ml-#{$i} {
    margin-left: #{$i*0.25}rem !important;
  }
  .mr-#{$i} {
    margin-right: #{$i*0.25}rem !important;
  }
}

@for $i from 0 through $maxPadding {
  .p-#{$i} {
    padding: #{$i*0.25}rem !important;
  }
  .px-#{$i} {
    padding-left: #{$i*0.25}rem !important;
    padding-right: #{$i*0.25}rem !important;
  }
  .py-#{$i} {
    padding-top: #{$i*0.25}rem !important;
    padding-bottom: #{$i*0.25}rem !important;
  }
  .pt-#{$i} {
    padding-top: #{$i*0.25}rem !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i*0.25}rem !important;
  }
  .pl-#{$i} {
    padding-left: #{$i*0.25}rem !important;
  }
  .pr-#{$i} {
    padding-right: #{$i*0.25}rem !important;
  }
}

.overflowY-auto {
  overflow-y: auto !important;
}

.overflow-unset {
  overflow: unset !important;
}

.d-none {
  display: none !important;
}

.m-auto {
  margin: auto !important;
}

.float-right {
  float: right;
}

.w-30 {
  width: 30% !important;
}

.w-50 {
  width: 50% !important;
}

.w-90 {
  width: 90% !important;
}

.w-100 {
  width: 100% !important;
}

.w-unset {
  width: unset !important;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.colorPrimary {
  color: $colorPrimary
}

.colorSecondary {
  color: $colorSecondary
}

.colorBlue {
  color: $colorBlue !important;
}

.colorGreen {
  color: $colorGreen !important;
}

.colorWhite {
  color: $colorWhite !important;
}

.bgColorWhite {
  background: $colorWhite !important;
}

.bgColorBlue {
  background: $colorBlue !important;
}

.bgColorGreen {
  background: $colorGreen !important;
}

.colorDanger {
  color: $colorDanger !important;
}

.colorSecondary {
  color: $colorSecondary !important;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.d-block {
  display: block !important;
}

.border-0 {
  border: none !important;
}

.border2px {
  border: 2px solid rgba(0, 0, 0, 0.12) !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.text-inherit {
  text-transform: inherit !important;
}

.text-decoration-none {
  text-decoration: none;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.d-flex {
  display: flex !important;
}

.w-unset {
  width: unset !important;
}

.w-auto {
  width: auto !important;
}

.h-unset {
  height: unset !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.grid-column-gap-05 {
  grid-column-gap: 0.5rem !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.removeChildMargin {
  p {
    margin: 0
  }
}

.mainContent {
  top: 64px;
  left: 0;
  position: fixed;
  width: 100%;
  height: calc(100vh - 64px)
}

table {
  tbody {
    tr {
      td {
        padding: 0 18px !important
      }
    }
  }
}

.dataNotAvailable {
  padding: 5rem !important;
  text-align: center;
}

.detailsPageContainer {
  padding-bottom: 60px;
  max-width: 1300px;
  margin: auto;
}

.profileActionRequiredBadge {
  top: 10% !important;
  //top: 3% !important;
  //top: 40% !important;
  //right: 30px !important;
  //animation: alertPulsate 1s ease-out;
  //animation-iteration-count: infinite;
  opacity: 1;
}

.actionRequiredBadge {
  height: 8px;
  padding: 0;
  min-width: 8px;
  border-radius: 4px;
  background-color: rgb(220, 0, 78);
  //animation: alertPulsate 1s ease-out;
  //animation-iteration-count: infinite;
  opacity: 1;
}

@-webkit-keyframes alertPulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

@media only screen and (max-width: 1024px) {

  .d-md-flex {
    display: flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .d-md-block {
    display: block !important;
  }
}

@media only screen and (max-width: 768px) {

  @for $i from 0 through $maxPadding {
    .p-sm-#{$i} {
      padding: #{$i*0.25}rem !important;
    }
    .px-sm-#{$i} {
      padding-left: #{$i*0.25}rem !important;
      padding-right: #{$i*0.25}rem !important;
    }
    .py-sm-#{$i} {
      padding-top: #{$i*0.25}rem !important;
      padding-bottom: #{$i*0.25}rem !important;
    }
    .pt-sm-#{$i} {
      padding-top: #{$i*0.25}rem !important;
    }
    .pb-sm-#{$i} {
      padding-bottom: #{$i*0.25}rem !important;
    }
    .pl-sm-#{$i} {
      padding-left: #{$i*0.25}rem !important;
    }
    .pr-sm-#{$i} {
      padding-right: #{$i*0.25}rem !important;
    }
  }

  @for $i from 0 through $maxMargin {
    .m-sm-#{$i} {
      margin: #{$i*0.25}rem !important;
    }
    .mx-sm-#{$i} {
      margin-left: #{$i*0.25}rem !important;
      margin-right: #{$i*0.25}rem !important;
    }
    .my-sm-#{$i} {
      margin-top: #{$i*0.25}rem !important;
      margin-bottom: #{$i*0.25}rem !important;
    }
    .mt-sm-#{$i} {
      margin-top: #{$i*0.25}rem !important;
    }
    .mb-sm-#{$i} {
      margin-bottom: #{$i*0.25}rem !important;
    }
    .ml-sm-#{$i} {
      margin-left: #{$i*0.25}rem !important;
    }
    .mr-sm-#{$i} {
      margin-right: #{$i*0.25}rem !important;
    }
  }

  .flex-direction-column-sm-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-direction-sm-row {
    flex-direction: row !important;
  }

  .text-sm-center {
    text-align: center !important;
  }

  .d-sm-block {
    display: block !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-direction-sm-reverse {
    flex-direction: row-reverse;
  }

  .flex-direction-sm-column-reverse {
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 425px) {

  @for $i from 0 through $maxPadding {
    .p-xs-#{$i} {
      padding: #{$i*0.25}rem !important;
    }
    .px-xs-#{$i} {
      padding-left: #{$i*0.25}rem !important;
      padding-right: #{$i*0.25}rem !important;
    }
    .py-xs-#{$i} {
      padding-top: #{$i*0.25}rem !important;
      padding-bottom: #{$i*0.25}rem !important;
    }
    .pt-xs-#{$i} {
      padding-top: #{$i*0.25}rem !important;
    }
    .pb-xs-#{$i} {
      padding-bottom: #{$i*0.25}rem !important;
    }
    .pl-xs-#{$i} {
      padding-left: #{$i*0.25}rem !important;
    }
    .pr-xs-#{$i} {
      padding-right: #{$i*0.25}rem !important;
    }
  }

  @for $i from 0 through $maxMargin {
    .m-xs-#{$i} {
      margin: #{$i*0.25}rem !important;
    }
    .mx-xs-#{$i} {
      margin-left: #{$i*0.25}rem !important;
      margin-right: #{$i*0.25}rem !important;
    }
    .my-xs-#{$i} {
      margin-top: #{$i*0.25}rem !important;
      margin-bottom: #{$i*0.25}rem !important;
    }
    .mt-xs-#{$i} {
      margin-top: #{$i*0.25}rem !important;
    }
    .mb-xs-#{$i} {
      margin-bottom: #{$i*0.25}rem !important;
    }
    .ml-xs-#{$i} {
      margin-left: #{$i*0.25}rem !important;
    }
    .mr-xs-#{$i} {
      margin-right: #{$i*0.25}rem !important;
    }
  }

  .align-items-sm-flex-start {
    align-items: flex-start !important;
  }

  .d-xs-none {
    display: none !important;
  }
  .text-xs-right {
    text-align: right !important;
  }
  .text-xs-left {
    text-align: left !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .text-xs-center {
    text-align: center !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }

  .flex-direction-sm-column {
    flex-direction: column;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-between {
    justify-content: space-between !important;
  }

}

.MuiFormLabel-root.Mui-error {
  font-size: 0.75rem;
  margin-left: 14px;
  margin-top: 5px;
}
