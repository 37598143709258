@import "./../../../Variables";

.inputContainer {
  .inputLabels {
    display: unset !important;
    font-size: 0.875rem !important;
    color: black !important;
  }

  input {
    padding: 14px
  }

  .disabled {
    background: $inputDisabledBg;
    color: $colorPrimary;
  }

  .inputFields {
    width: 100%;
    background-color: white;
    margin-top: 0.3rem !important;
  }

  .error {
    -webkit-border-radius: 4px !important;
    -moz-border-radius: 4px !important;
    border-radius: 4px !important;
    border: 1px solid red !important;
  }
}
