@import "src/Variables";

.form {
  width: 100%;
}

.title {
  margin: auto;
  color: $colorBlue;
}

.formControl {
  width: 100%;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  margin: 2px 5px;
}

.gridItem {
  margin: auto !important;
}

.gridContainer {
  flex-grow: 1;

  .titleContainer {
    margin: auto;
  }
}

.innerGridContainer {
  .categoryChips {
    padding: 5px 10px !important;
  }
}

.listingBtnGroup {
  display: flex;
  justify-content: flex-end;

  button {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    text-transform: inherit;
  }

  .btnDeleteSmall {
    display: none;
  }

  .btnDelete {
    color: white;
    background-color: red;

    &:hover {
      color: white;
      background-color: rgb(207 93 133);
    }
  }

  .btnOwnership {
    color: white;
  }
}

.btnContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    text-transform: inherit;
  }

  .btnSubmit {
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    color: white;
    background-color: $colorBlue;
    float: right;

    &:hover {
      background-color: rgb(43, 128, 213);
    }
  }
}

.inputLabels {
  display: unset !important;
  font-size: 0.875rem !important;
  color: black !important;
}

.inputFields {
  width: 100%;
  background-color: white;
  margin-top: 0.3rem !important;

  input {
    padding: 12px;
  }

  &:focus {
    outline: none;
  }
}

.inputPriceFields {
  width: 100%;
  background-color: white;
  margin-top: 0.3rem !important;

  input {
    padding: 12px 12px 12px 30px;
  }
}

.priceInputLayout {
  padding-left: 10px;
  position: relative;
}

.icon {
  z-index: 1;
  top: 1.2rem;
  left: 0.5rem;
  position: absolute;
  font-size: 14px !important;
}

.dropdown {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  padding: 5px 12px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87) !important;
  }

  &:before {
    border-bottom: none !important;
  }

  .dropdown {
    &:after {
      border: none !important;
    }
  }
}

.durationDate {
  font-weight: bold;
}


@media only screen and (max-width: 768px) {

  .form {
    padding-bottom: $mobileBottomNavHeight
  }
  .listingBtnGroup {
    .btnDeleteSmall {
      display: block;
    }

    .btnDeleteLarge {
      display: none;
    }
  }
}